import React, { useState, useEffect } from 'react';
import tw from 'twin.macro';
import styled from 'styled-components';

const Banner = styled.div`
  ${tw`fixed w-full text-white z-50 py-4 flex justify-between font-sans`}
  background-color: #00000082;
  z-index: 5;
  display: ${(props) => (props.hidden ? 'none' : 'flex')}
`;

const GoBack = styled.a`
  ${tw`hover:opacity-50 flex items-center pr-4 text-white no-underline ml-2`}
`;

const Label = styled.div`
  ${tw`flex items-center text-center`}
`;

const Button = styled.button`
  ${tw`px-6 py-2 hover:shadow-xl hover:opacity-75 mr-2 border-none`}
  background-color: #577bd1;
  color: #fff;
`;

const Spacer = styled.div`
    ${tw`bg-black`}
    padding-top: ${(props) => (props.hidden ? '0px' : '66px')};
`;

const DemoBanner = ({ children }) => {
  const [hasMounted, setHasMounted] = useState(false);
  useEffect(() => {
    setHasMounted(true);
  }, []);
  if (typeof window === 'undefined') {
    return (
      <>{children}</>
    );
  }
  const hidden = window.location.host !== 'dorian.gatsbytemplates.io';
  return (
    <div>
      {hasMounted && (
      <>
        <Banner
          style={{ backgroundColor: '#00000082' }}
          hidden={hidden}
        >
          <GoBack
            href="https://gatsbytemplates.io/theme/dorian-gatsby-theme/"
            alt="back to gatsbytemplates"
          >
            Go Back
          </GoBack>
          <Label>
            Gatsby Dorian Demo
          </Label>
          <a
            href="https://gum.co/KIaWK"
            alt="buy theme"
          >
            <Button
              type="button"
              style={{ backgroundColor: '#577bd1' }}
            >
              Buy
            </Button>
          </a>
        </Banner>
        <Spacer hidden={hidden} />
      </>
      )}
      {children}
    </div>
  );
};

export default DemoBanner;
