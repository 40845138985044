import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import Helmet from 'react-helmet';
import slugify from 'react-slugify';
import { JsonCreatorPlugin } from 'gatsby-tinacms-json';
import { withPlugin } from 'tinacms';
import HeaderSection from 'gatsby-theme-dorian/src/sections/HeaderSection';
import Transition from '../utils/transition';
import Navigation from './Navigation';
import Theme from './Theme';
import Footer from './Footer';
import DemoBanner from '../components/demoBanner';


const MasterLayout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query MasterLayoutQuery {
      site: settingsJson(
        fileRelativePath: { eq: "/content/settings/site.json" }
      ) {
        title
      }
      theme: settingsJson(
        fileRelativePath: { eq: "/content/settings/theme.json" }
      ) {
        ...globalTheme

        rawJson
        fileRelativePath
      }
    }
  `);

  return (
    <DemoBanner>
      <Theme>
        <Transition animationsEnabled={data.theme.animations}>
          <Helmet>
            <script async src="https://cdn.jsdelivr.net/npm/focus-visible@5.0.2/dist/focus-visible.min.js" />
          </Helmet>
          <Site>
            <HeaderSection
              siteTitle={data.site.title}
              headerOptions={data.theme.header}
            >
              <Navigation />
            </HeaderSection>
            {children}
            <Footer siteTitle={data.site.title} />
          </Site>
        </Transition>
      </Theme>
    </DemoBanner>
  );
};

const CreatePageButton = new JsonCreatorPlugin({
  label: 'New Page',
  filename(form) {
    let slug = slugify(form.title.toLowerCase());
    return `content/pages/${slug}.json`;
  },
  fields: [
    {
      name: 'title', label: 'Title', component: 'text', required: true,
    },
    {
      name: 'path', label: 'Path', component: 'text', required: true,
    },
  ],
  data(form) {
    return {
      title: form.title,
      path: form.path,
    };
  },
});

export default withPlugin(MasterLayout, [CreatePageButton]);

export const Site = styled.div`
  position: relative;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;

  > * {
    flex: 1 0 auto;
  }
`;
