import React from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import tw from 'twin.macro';
import { AnchorLink } from 'gatsby-plugin-anchor-links';

export const SiteLink = styled(AnchorLink)`
  ${tw`
    relative flex items-center self-stretch m-0 z-10
  `}
  transition: all 150ms ${(p) => p.theme.easing};
`;

export const Wrapper = styled.div`
  ${tw`
    w-full container mx-auto relative px-10 sm:px-0
  `}
`;

const NavLink = styled(({ children, navOpen, ...styleProps }) => (
  <Link
    data-a11y="false"
    tabIndex={navOpen ? '0' : '-1'}
    activeClassName="active"
    {...styleProps}
  >
    <span>{children}</span>
  </Link>
))`
  ${tw`
    flex flex-auto items-center py-4 px-3 text-center no-underline sm:leading-none
    opacity-50 focus:opacity-100 hover:opacity-100 overflow-visible z-10
  `}
  transition: all 150ms ${(p) => p.theme.easing};

  &:hover,
  &:active,
  &.active {
    opacity: 1;
  }

  span {
    ${tw`
      block w-full
    `}
  }
`;

NavLink.defaultProps = {
  navOpen: true,
};

const FooterContainer = styled.footer`
  background-color: ${(p) => p.theme.color.background};
`;

const NavItem = styled.li`
  ${tw`flex flex-initial items-stretch`}
`;

const Listing = styled.ul`
  ${tw`
    flex flex-col sm:flex-row
  `}
  > li {
    ${tw`
      border-none
    `}
  }
`;

const FooterNav = styled.div`
  ${tw`
    flex pt-12 pb-4 sm:pb-24 relative text-base sm:text-xl font-bold overflow-hidden
  `}
  color: ${(p) => p.theme.color.primary};

`;

const FooterWrapper = styled(Wrapper)`
  ${tw`
    flex-col sm:flex-col
  `}
`;

const FooterLink = styled(NavLink)`
  ${tw`
    opacity-100 text-left px-1 sm:px-3 leading-loose
  `}
  &:hover, &:focus {
    color: ${(p) => p.theme.color.active};
  }
`;

const FooterSiteInfo = styled.div`
  ${tw`
    flex flex-col sm:flex-row justify-between items-center pt-16 
    sm:pt-40 pb-24
  `}
`;

const FooterSiteInfoNav = styled.div`
  ${tw`flex text-base w-full`}
  > ul {
    ${tw`
      flex flex-row w-full justify-between 
      sm:justify-start
    `}
  }
  a:hover {
    color: ${(props) => props.theme.color.white};
  }
  > li {
    ${tw`
      border-none
    `}
  }
`;

const FooterSiteLink = styled(SiteLink)`
  ${tw`
    flex justify-end pt-8 sm:pt-0
  `};
`;

const FooterSection = ({ siteTitle, footerMenu, logo }) => (
  <FooterContainer id="footer">
    <FooterWrapper>
      <FooterSiteInfo>
        <FooterSiteInfoNav>
          <Listing>
            {footerMenu.footerMenuItems.map((item) => (
              <NavItem key={item.label}>
                <FooterLink partiallyActive={item.link !== '/'} to={item.link}>
                  {item.label}
                </FooterLink>
              </NavItem>
            ))}
          </Listing>
        </FooterSiteInfoNav>
        <FooterSiteLink to="/">
          <Img fixed={logo.childImageSharp.fixed} alt={siteTitle} />
        </FooterSiteLink>
      </FooterSiteInfo>
    </FooterWrapper>
  </FooterContainer>
);

const Footer = () => {
  const data = useStaticQuery(graphql`
    query footerQuery {
      site: settingsJson(
        fileRelativePath: { eq: "/content/settings/site.json" }
      ) {
        title
      }
      nav: settingsJson(
        fileRelativePath: { eq: "/content/settings/menu.json" }
      ) {
        ...nav
      }
      footerNav: settingsJson(
        fileRelativePath: { eq: "/content/settings/footerMenu.json" }
      ) {
        ...footerNav
      }
      theme: settingsJson(
        fileRelativePath: { eq: "/content/settings/theme.json" }
      ) {
        header {
          logo {
            childImageSharp {
              fixed(width: 90, quality: 100) {
                ...GatsbyImageSharpFixed
              }
            }
          }
        }
      }
    }
  `);

  return (
    <FooterSection
      siteTitle={data.site.title}
      menu={data.nav}
      footerMenu={data.footerNav}
      logo={data.theme.header.logo}
    />
  );
};

export default Footer;

export const footerNavFragment = graphql`
  fragment footerNav on SettingsJson {
    footerMenuItems {
      link
      label
    }
  }
`;

export const FooterNavForm = {
  label: 'Footer menu',
  fields: [
    {
      label: 'Footer menu',
      name: 'rawJson.footerMenuItems',
      component: 'group-list',
      itemProps: (item) => ({
        label: item.label,
      }),
      fields: [
        {
          label: 'Label',
          name: 'label',
          component: 'text',
          parse(value) {
            return value || '';
          },
        },
        {
          label: 'Link',
          name: 'link',
          component: 'text',
          parse(value) {
            return value || '';
          },
        },
      ],
    },
  ],
};
