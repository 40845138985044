/* eslint-disable no-shadow */
import React from 'react';
import { mix } from 'polished';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { GlobalStyles } from 'gatsby-theme-dorian/src/styles/mainStyles';


export const ThemeContext = React.createContext();

const Theme = ({ children }) => {
  const data = useStaticQuery(graphql`
    query ThemeQuery {
      settingsJson(fileRelativePath: { eq: "/content/settings/theme.json" }) {
        ...globalTheme
      }
    }
  `);

  const globalTheme = data.settingsJson;
  const theme = {
    color: {
      black: globalTheme.color.black,
      white: globalTheme.color.white,
      primary: globalTheme.color.primary,
      secondary: globalTheme.color.secondary,
      foreground: mix(0.8, globalTheme.color.white, globalTheme.color.black),
      background: globalTheme.color.background,
      active: globalTheme.color.active,
    },
    easing: globalTheme.easing,
    header: globalTheme.header,
    hero: globalTheme.hero,
    animations: globalTheme.animations,
  };

  return (
    <ThemeContext.Provider
      value={{ theme }}
    >
      <ThemeContext.Consumer>
        {({ theme }) => (
          <ThemeProvider theme={theme}>
            <>
              <TinaOverrideGlobalStyle primary={theme.color.primary} />
              <GlobalStyles />
              {children}
            </>
          </ThemeProvider>
        )}
      </ThemeContext.Consumer>
    </ThemeContext.Provider>
  );
};

export default Theme;

const TinaOverrideGlobalStyle = createGlobalStyle`
  :root {
    --tina-color-primary-light: ${(props) => props.primary};
    --tina-color-primary: ${(props) => props.primary};
    --tina-color-primary-dark: ${(props) => props.primary};
  }
`;

export const globalThemeFragment = graphql`
  fragment globalTheme on SettingsJson {
    color {
      black
      white
      primary
      secondary
      background
      active
    }
    easing
    header {
      overline
      underline
      transparent
      sticky
      logo {
        childImageSharp {
          fixed(width: 90, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
    hero {
      colored
    }
    animations
  }
`;

export const ThemeForm = {
  label: 'Theme',
  fields: [
    {
      label: 'Color',
      name: 'rawJson.color',
      component: 'group',
      fields: [
        {
          label: 'Background',
          name: 'background',
          component: 'color',
          colorFormat: 'hex',
        },
        {
          label: 'Active Link',
          name: 'active',
          component: 'color',
          colorFormat: 'hex',
        },
        {
          label: 'Black',
          name: 'black',
          component: 'color',
          colorFormat: 'hex',
        },
        {
          label: 'White',
          name: 'white',
          component: 'color',
          colorFormat: 'hex',
        },
        {
          label: 'Primary',
          name: 'primary',
          component: 'color',
          colorFormat: 'hex',
        },
        {
          label: 'Secondary',
          name: 'secondary',
          component: 'color',
          colorFormat: 'hex',
        },
      ],
    },
    {
      label: 'Header',
      name: 'rawJson.header',
      component: 'group',
      fields: [
        {
          label: 'Overline',
          name: 'overline',
          component: 'toggle',
          parse(value) {
            return value || false;
          },
        },
        {
          label: 'Underline',
          name: 'underline',
          component: 'toggle',
          parse(value) {
            return value || false;
          },
        },
        {
          label: 'Transparent',
          name: 'transparent',
          component: 'toggle',
          parse(value) {
            return value || false;
          },
        },
        {
          label: 'Sticky header',
          name: 'sticky',
          description: 'Sticky header on top while scroll',
          component: 'toggle',
          parse(value) {
            return value || false;
          },
        },
        {
          label: 'Logo',
          name: 'logo',
          component: 'image',
          parse: (filename) => `../images/${filename}`,
          uploadDir: () => '/content/images/',
          previewSrc: (formValues) => {
            if (!formValues.jsonNode.header || !formValues.jsonNode.header.logo) return '';
            return formValues.jsonNode.header.logo.childImageSharp.fixed.src;
          },
        },
      ],
    },
    {
      label: 'Hero',
      name: 'rawJson.hero',
      component: 'group',
      fields: [
        {
          label: 'Colored headline',
          name: 'colored',
          component: 'toggle',
          parse(value) {
            return value || false;
          },
        },
      ],
    },
    {
      label: 'Animations',
      name: 'rawJson.animations',
      component: 'toggle',
      parse(value) {
        return value || false;
      },
    },
  ],
};
