/* eslint-disable react/display-name */
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Location } from '@reach/router';

const Transition = ({ children, location }) => {
  const duration = 0.1;

  const variants = {
    initial: {
      opacity: 0.5,
    },
    enter: {
      opacity: 1,
      transition: {
        duration,
        delay: duration,
        when: 'beforeChildren',
      },
    },
    exit: {
      opacity: 0.5,
      transition: { duration },
    },
  };

  return (
    <AnimatePresence>
      <motion.main
        key={location.pathname}
        id="main"
        variants={variants}
        initial="initial"
        animate="enter"
        exit="exit"
      >
        {children}
      </motion.main>
    </AnimatePresence>
  );
};

export default (props) => {
  if (props.animationsEnabled) {
    return (
      <Location>
        {(locationProps) => <Transition {...locationProps} {...props} />}
      </Location>
    );
  }
  return (
    <Location>
      {(locationProps) => <div {...locationProps} {...props}>{props.children}</div>}
    </Location>

  );
};
