import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Navbar from 'gatsby-theme-dorian/src/components/Navbar';


const Navigation = () => {
  const data = useStaticQuery(graphql`
    query navQuery {
      settingsJson(fileRelativePath: { eq: "/content/settings/menu.json" }) {
        ...nav
      }
    }
  `);

  return (
    <Navbar menu={data.settingsJson} />
  );
};

export default Navigation;


export const navFragment = graphql`
  fragment nav on SettingsJson {
    menuItems {
      link
      label
    }
  }
`;

export const NavForm = {
  label: 'Menu',
  fields: [
    {
      label: 'Main Menu',
      name: 'rawJson.menuItems',
      component: 'group-list',
      itemProps: (item) => ({
        label: item.label,
      }),
      fields: [
        {
          label: 'Label',
          name: 'label',
          component: 'text',
          parse(value) {
            return value || '';
          },
        },
        {
          label: 'Link',
          name: 'link',
          component: 'text',
          parse(value) {
            return value || '';
          },
        },
      ],
    },
  ],
};
